import { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

// import { DeviceRow } from './DeviceRow.js'

import { DeviceSettingsModal } from './DeviceSettingsModal.js'
import { ConfirmationDialog } from './common/ConfirmationDialog.js';
import { DeviceEventModal } from './DeviceEventModal.js';

export function DeviceTable(props) {
	const backendAPI = props.backendAPI;
    const websocket = props.websocket;
	
    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

    useEffect(() => {
        fetchDevices();
        // const devicesInterval = setInterval(fetchDevices, 10000);
        // return () => {
        //     clearInterval(devicesInterval);
        // };
    }, []);

    async function fetchDevices() {
        const response = await backendAPI.getDevices();
        if(response && response.data) {
            setDevices(response.data.devices);
        }
        setIsLoading(false);
    }

    useEffect(() => {
    }, [devices]);

    async function editCallback(device) {
        try {
            const response = await backendAPI.updateDevice(device);
            fetchDevices();
            return response;
		} catch (error) {
            if (error.response) {
                console.error('Error response:', error.response);
                return error.response;
            }
			console.error('Error executing device update:', error);
            return null;
		}
    }

    async function addCallback(device) {
        try {
            const response = await backendAPI.createDevice(device);
            fetchDevices();
            return response;
		} catch (error) {
            if (error.response) {
                console.error('Error response:', error.response);
                return error.response;
            }
			console.error('Error executing device update:', error);
            return null;
		}
    }

    const handleShowConfirmDelete = () => {
        setIsConfirmDeleteOpen(true);
      };
    
    async function handleConfirmDelete(dev) {
        try {
            const response = await backendAPI.deleteDevice(dev.id);
            if(response.status === 200) {
                fetchDevices();
                setIsConfirmDeleteOpen(false);
                return true;
            }
		} catch (error) {
			console.error('Error deleting device', error);
            return false;
		}
        
        return true;
    };

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteOpen(false);
    };

	return (
		<div className="devices">
            <Row className="device-info gx-0 border-light-grey">
                <Col className="mt-1 mb-1" xs={2} lg={2}>MAC Address:</Col>
                <Col className="mt-1 mb-1" xs={2} lg={2}>Serial Number:</Col>
                <Col className="mt-1 mb-1" xs={3} lg={3}>Location:</Col>
                <Col className="mt-1 mb-1" xs={3} lg={4}>Description:</Col>
                <Col className="mt-1 mb-1" xs={2} lg={1}>
                {isLoading === false &&
                    <DeviceSettingsModal device={null} submit_callback={addCallback} icon={faPlus} title="Add Device"/>
                }
                </Col>
            </Row>
            {isLoading &&
                <Row>
                    <Col className="mt-1 mb-1" xs={2} lg={4}/>
                    <Col className="mt-1 mb-1" xs={8} lg={4} style={{ display: 'flex', justifyContent: 'center'}}>
                        <label className="text-values-white">FETCHING DEVICES...</label>
                    </Col>
                    <Col className="mt-1 mb-1" xs={2} lg={4}/>
                </Row>
            }
            {isLoading === false && devices && devices.map((device, index) => {
                return (
                    <div key={index} style={{ backgroundColor: index % 2 === 0 ? '#101014' : '#2d2d39' }} className="device">
                        <Row className="device-info gx-0 border-light-grey">
                            <Col className="mt-1 mb-1" xs={2} lg={2}><label className="text-values-white">{device.mac_address}</label></Col>
                            <Col className="mt-1 mb-1" xs={2} lg={2}><label className="text-values-white">{device.serial_number}</label></Col>
                            <Col className="mt-1 mb-1" xs={3} lg={3}><label className="text-values-white">{device.location}</label></Col>
                            <Col className="mt-1 mb-1" xs={3} lg={4}><label className="text-values-white">{device.description}</label></Col>
                            <Col className="mt-1 mb-1" xs={2} lg={1}>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={3} lg={3}>
                                        <DeviceSettingsModal device={device} submit_callback={editCallback} icon={faEdit} title="Edit Device"/>
                                    </Col>
                                    <Col className="mt-1 mb-1" xs={3} lg={3}>
                                        <DeviceEventModal device={device} backendAPI={backendAPI} websocket={websocket} liveDeviceEventUpdate={true} device_event={null}/>
                                    </Col>
                                    <Col className="mt-1 mb-1" xs={3} lg={3}>
                                        <FontAwesomeIcon icon={faTrash} style={{color: "red", cursor: "pointer"}} onClick={() => handleShowConfirmDelete()}/>
                                        <ConfirmationDialog 
                                            title = "Delete Device?"
                                            body = {"Delete Device:\n\tMAC address " + device.mac_address + "\n\tSerial Number: " + device.serial_number + "\n\tDescription: " + device.description}
                                            show={isConfirmDeleteOpen}
                                            onHide={handleCloseConfirmDelete}
                                            onConfirm={handleConfirmDelete}
                                            callback_user_data={device}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
            )}
        </div>
	)
};