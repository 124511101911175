import { React, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './assets/styles/App.css';

import RootRedirect from './components/RootRedirect.js';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

import { BackendAPI } from './API/BackendAPI.js';
import { Websocket } from './API/Websocket.js'

function App() {

	const notAuthorised = () => {
		if(!window.location.href.includes('/login'))
			window.location.href = '/login';
	}

	const backendAPIRef = useRef(null);
	backendAPIRef.current = new BackendAPI(process.env.REACT_APP_API_BASE_URL, notAuthorised);
	const websocketRef = useRef(null);
	websocketRef.current = new Websocket(process.env.REACT_APP_API_BASE_URL);

	return (
		<Router>
			<Routes>
				<Route path="/" element={<RootRedirect backendAPI={backendAPIRef.current} />} />
				<Route path="/login" element={<Login backendAPI={backendAPIRef.current} />} />
				<Route path="/dashboard" element={<Dashboard backendAPI={backendAPIRef.current} websocket={websocketRef.current}/>} />
			</Routes>
		</Router>
	);
}

export default App;
