import axios from 'axios';

export class BackendAPI {
	constructor(base_url, onUnauthorized) {
		this.interface = axios.create({
			baseURL: base_url,
			timeout: 20000
		});

		this.onUnauthorized = onUnauthorized;

		this.interface.interceptors.request.use((config) => {
			const token = localStorage.getItem('authToken');
			if (token) {
				config.headers['Authorization'] = `Bearer ${token}`;
			}
			return config;
		});

		this.interface.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response && error.response.status === 401 && !error.config.url.includes('/login')) {
					localStorage.removeItem('token');
					if (typeof onUnauthorized === 'function') {
						this.onUnauthorized();
					}
				}
				return Promise.reject(error);
			}
		);
	}

	async getDevices(pageNum = 0, pageSize = 10) {
		const response = await this.interface.get(`/device?offset=${pageNum * pageSize}&limit=${pageSize}`);
		return response;
	}

	async createDevice(device) {
		const response = await this.interface.post(`/device`, device);
		return response;
	}

	async updateDevice(device) {
		const response = await this.interface.put(`/device/${device.id}`, device);
		return response;
	}

	async deleteDevice(device_id) {
		const response = await this.interface.delete(`/device/${device_id}`);
		return response;
	}

	async getDeviceEventsByDeviceId(device_id, offset, limit) {
		const response = await this.interface.get(`/device_event?device_id=${device_id}&offset=${offset}&limit=${limit}`);
		return response;
	}

	async getDeviceEvents(offset, limit) {
		const response = await this.interface.get(`/device_event?offset=${offset}&limit=${limit}`);
		// console.log(response);
		return response;
	}

	async isLoggedIn() {
		const token = localStorage.getItem('authToken');
		if (token) {
			try {
					const response = await this.interface.get('/auth/validateToken', {
							headers: { Authorization: `Bearer ${token}` },
					});
					if (response.status === 200) {
							return true;
					}
			} catch (error) {
					return false;
			}
	}
	}
	
}
