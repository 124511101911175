import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { DeviceTable } from './DeviceTable.js';
import { DeviceEventTable } from './DeviceEventTable.js';
import logo from '../assets/generic_logo.png'


function Dashboard(props) {
    const backendAPI = props.backendAPI;
    const websocket = props.websocket;

    function logout() {
        localStorage.removeItem('authToken');
    }

    return (
        <Container fluid style={{height: '100vh'}} className="background-light-grey text-default-color">
            <Row>
                <Navbar collapseOnSelect expand={false} bg="light" variant="light" fixed="top">
                    <Container className="d-flex justify-content-center">
                        <img
                            src={logo}
                            height="50"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                        <div className="d-flex justify-content-center flex-grow-1">
                            <Navbar.Brand href="/">TRACER</Navbar.Brand>
                        </div>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                            <Nav.Link href="/login" onClick={logout}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>

            <div style={{height:"50px"}}/>
            <Row style={{paddingLeft: 0, paddingRight: 0}} className="justify-content-md-center">
                <Col className="mt-1 mb-1" xs={12} lg={10} >
                    <hr/>
                    <center className="text-headings text-large">DEVICES</center>
                    <DeviceTable backendAPI={backendAPI} websocket={websocket} />
                    <hr/>
                    <center className="text-headings text-large">DEVICE EVENTS</center>
                    <DeviceEventTable backendAPI={backendAPI} websocket={websocket} />
                </Col>
            </Row>

            <Row className="fs-3">
                
            </Row>

        </Container>
    );
}

export default Dashboard;
    