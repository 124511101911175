import { useState, useEffect, useRef } from 'react';
import { Row, Col, Modal, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

export function DeviceEventModal(props) {
	const device = props.device;
    const websocket = props.websocket;
    const backendAPI = props.backendAPI;
    const liveDeviceEventUpdate = props.liveDeviceEventUpdate;
    const [showModal, setShowModal] = useState(false);
    const showModalRef = useRef(showModal);
    const [device_event, setDeviceEvent] = useState(props.device_event);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        showModalRef.current = showModal;
    }, [showModal]);

	async function openModal() {
        setShowModal(true);
        if(props.device_event && !liveDeviceEventUpdate) {
            setDeviceEvent(props.device_event);
        } else {
            setLoading(true);
            // Get last device event and subscribe to websocket event
            try {
                const response = await backendAPI.getDeviceEventsByDeviceId(device.id, 0, 1);
                if(response.data && 'device_events' in response.data && response.data.device_events.length > 0) {
                    setDeviceEvent(response.data.device_events[0]);
                }
            } catch (error) {
                console.error('Error getting last device_event:', error);
            } finally {
                setLoading(false);
            }
        }

        if(liveDeviceEventUpdate) {
            websocket.registerDeviceEventCallback(handleWebsocketDeviceEventEvent);
        }
	};

	function closeModal() {
        if(liveDeviceEventUpdate) {
            websocket.deregisterDeviceEventCallback(handleWebsocketDeviceEventEvent);
        }
		setShowModal(false);
	};

	async function handleWebsocketDeviceEventEvent(data) {
        if(showModalRef.current) {
            if(device && data.device_id === device.id)
                setDeviceEvent(data);
        }
	}

	return (
		<div style={{float: "left"}}>
			<FontAwesomeIcon icon={faEye} style={{ cursor: "pointer", marginRight: '20px' }} onClick={() => openModal()}/>
			<Modal show={showModal} onHide={() => closeModal()}>
				<Modal.Header closeButton>
                    {liveDeviceEventUpdate ? (
					    <Modal.Title>Live Device Events</Modal.Title>
                    ) : (
                        <Modal.Title>Device Event Info</Modal.Title>
                    )}
				</Modal.Header>
				<Modal.Body>
                        {/* <Row>
                            <Col className="mt-1 mb-1" xs={12} lg={12}>
                                <label className="text-values-dark-grey">MAC Address: {device.ip_address}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1 mb-1" xs={12} lg={12}>
                                <label className="text-values-dark-grey">Serial Number: {device.serial_number}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1 mb-1" xs={12} lg={12}>
                                <label className="text-values-dark-grey">Description: {device.description}</label>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center'}}>
                            <label className="text-values-dark-grey text-large">Last Device Event</label>
                                
                            </Col>
                        </Row> */}
                        {loading ? (
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ) : device_event != null ? (
                            <Row className="border-light-grey">
                                <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <img src={`data:image/jpeg;base64,${device_event.overview_image ? device_event.overview_image : device_event.anpr_image}`} style={{ width: '100%'}} alt="Not available"></img>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <img src={`data:image/jpeg;base64,${device_event.anpr_image}`} style={{ width: '100%'}} alt="Not available"></img>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12}>
                                        <label className="text-values-dark-grey">Time: {device_event.event_time}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={6} lg={6}>
                                        <label className="text-values-dark-grey">VLN Text: {device_event.vln ? device_event.vln : '-'}</label>
                                    </Col>
                                    <Col className="mt-1 mb-1" xs={6} lg={6}>
                                        <label className="text-values-dark-grey">VLN Score: {device_event.vln_confidence}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={6} lg={6}>
                                        <label className="text-values-dark-grey">Vehicle Class: {device_event.vehicle_class}</label>
                                    </Col>
                                    <Col className="mt-1 mb-1" xs={6} lg={6}>
                                        <label className="text-values-dark-grey">Class Score: {device_event.vehicle_class_confidence}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12}>
                                        <label className="text-values-dark-grey">Direction: {device_event.direction}</label>
                                    </Col>
                                </Row>

                            </Row>
                            
                        ) : (
                            <Row>
                                <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                    <label className="text-values-dark-grey">No recorded Events</label>
                                </Col>
                            </Row>
                        )}
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</div>
	)
}
